import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const StyledWrapper = styled.div`
  margin:32px 16px 0;
  p{
    font-weight:800;
  }
`


const StyledWrap = styled.div`
${() => media.tablet(css`
  max-width:500px;
  margin:16px auto 32px;
`)}
  margin:8px 0 32px;
  padding:16px 0;
  border-top:2px #292929 solid;
  border-bottom:2px #292929 solid;
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  justify-content:space-between;
  .soy{
    width:40%;
  }
  .text{
    display:flex;
    flex-flow:column nowrap;
    align-items:flex-start;
    justify-content:space-between;
    width:55%;
    p{
      font-weight:800;
      font-size:14px;
      margin-bottom:0;
    }
    .gatsby-image-wrapper{
      width:50%;
      margin-left:50%;
    }
  }
`

const Inuria = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrapper>
      <p>{data.text.meetInuria.imgtxt[0]}</p>
      <StyledWrap>
        <StaticImage
          src='../../images/potatoImage.png'
          alt='チコリ'
          placeholder='none'
          className='soy'
        />
        <div className='text'>
          <p>{data.text.meetInuria.imgtxt[1]}</p>
          <StaticImage
            src='../../images/inuriaLogo.svg'
            alt='SoyGood by Francois'
            placeholder='none'
          />
        </div>
      </StyledWrap>
    </StyledWrapper>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "thirtyYearsPage"}) {
      meetInuria {
        imgtxt
      }
    }
  }
`

export default Inuria
